<template>
  <AnimatedContainer>
    <Container tag="section" space-y>
      <!-- TITLE -->
      <TextAppear
        v-if="data.heading"
        tag="h2"
        class="slide-up mb-12 px-container typo-heading-xl md:mb-20 md:px-container-lg lg:typo-heading-2xl"
        :text="data.heading"
      />

      <div
        class="relative flex flex-col gap-12 max-md:pr-container md:flex-row md:gap-24 md:px-container-md lg:gap-36 xl:gap-48 2xl:gap-60"
      >
        <!-- IMAGE -->
        <div class="max-w-3xl md:w-5/12">
          <div class="slide-left relative aspect-landscape w-full">
            <template v-for="(item, index) in data.items" :key="item.id">
              <AppImage
                v-if="item.image"
                :key="item.id"
                loading="lazy"
                :src="item.image"
                :style="`object-position: ${item.image?.focus_css};`"
                class="absolute aspect-landscape size-full object-cover transition-opacity delay-200 duration-700 ease-in-out"
                :class="[index === activeIndex ? 'opacity-100' : 'opacity-0']"
              />
            </template>
          </div>
        </div>

        <!-- TEXT -->
        <div
          class="flex max-w-text flex-col justify-between pl-14 md:w-6/12 md:pl-0"
        >
          <!-- ACCORDION -->

          <div class="relative mb-12">
            <template v-for="(item, index) in data.items" :key="item.id">
              <AccordionItem
                :controlled="false"
                :open="index === activeIndex"
                :initially-closed="index === 0 ? false : true"
                :duration="800"
                class="slide-up group"
                @update:open="toggle($event, index)"
              >
                <AccordionHeader
                  class="max-w-title"
                  :class="[
                    'relative cursor-pointer',
                    'mb-4 lg:mb-9',
                    'typo-label-md',
                    'opacity-40 aria-expanded:opacity-100',
                    'list-none [&::-webkit-details-marker]:hidden',
                    'before:absolute before:top-2.5 before:inline-block before:border-t-1.5 before:border-gray-500',
                    'before:transition-all before:duration-200 before:ease-in',
                    'before:w-2 lg:before:w-3 xl:before:w-4',
                    'aria-expanded:before:duration-500 aria-expanded:before:ease-out',
                    'aria-expanded:before:w-4 lg:aria-expanded:before:w-6 xl:aria-expanded:before:w-10',
                    'before:right-[calc(100%+1rem)] lg:before:right-[calc(100%+1.5rem)] xl:before:right-[calc(100%+2rem)]',
                  ]"
                  :aria-controls="item.id"
                >
                  {{ item.title }}
                </AccordionHeader>
                <AccordionBody :id="item.id">
                  <div
                    class="max-w-text pb-9 text-gray-300 typo-body-xs xl:typo-body-md"
                  >
                    {{ item.text }}
                  </div>
                </AccordionBody>
              </AccordionItem>
              <AppLink
                v-show="index === activeIndex"
                :to="localePath(item.link)"
                :show-link-arrow="true"
                class="slide-right absolute -bottom-18 right-0 ml-auto w-fit typo-button-md"
              >
                {{ item.link_text }}
              </AppLink>
            </template>
          </div>
        </div>
      </div>
    </Container>
  </AnimatedContainer>
</template>

<script setup lang="ts">
import type { MainComponentsAccordionWithImageFragment } from '#gql'

const localePath = useLocalePath()

const props = defineProps<{
  data: Enforce<MainComponentsAccordionWithImageFragment>
}>()

const activeItem =
  ref<Enforce<MainComponentsAccordionWithImageFragment>['items'][0]>()
const activeIndex = ref<number>(0)

function toggle(isOpen: boolean, index: number) {
  if (!isOpen && activeIndex.value === index) {
    if (activeIndex.value + 1 > props.data.items.length - 1) {
      activeIndex.value = 0
    } else {
      activeIndex.value = activeIndex.value + 1
    }
  } else if (isOpen) {
    activeIndex.value = index
  }

  activeItem.value = props.data.items[activeIndex.value]
}

onMounted(() => {
  activeItem.value = props.data.items[0]
})
</script>
